.gray {
    background-color: #636466 !important;
}

.green {
    background-color: #2e6337 !important;
}

.lime {
    background-color: #7f9530 !important;
}

.blue {
    background-color: #67a1e0 !important;
}

.purple {
    background-color: #682f8e !important;
}

.azure {
    background-color: #392ebb !important;
}

.blush {
    background-color: #d8677b !important;
}

.red {
    background-color: #b5392d !important;
}

.aqua {
    background-color: #81d486 !important;
}

.brown {
    background-color: #774b1a !important;
}

.coral {
    background-color: #db8f51 !important;
}

.yellow {
    background-color: #ecda6c !important;
}
